<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title class="px-0 pb-1">
          <v-container fluid class="px-0 py-0 mb-2">
            <v-row align="end" class="px-8">
              <v-col cols="auto">
                <v-btn
                  height="29"
                  max-width="56"
                  min-width="12"
                  class="label text-lowercase font-weight-regular"
                  depressed
                  color="secondary"
                  :ripple="false"
                >
                  {{ termName }}
                </v-btn>
              </v-col>

              <v-col cols="auto">
                <h3 class="text-period main--text font-weight-regular mb-0">
                  {{ termPeriod }}
                </h3>
              </v-col>

              <v-col cols="auto" class="pl-0">
                <h5 class="text-date font-weight-regular mb-0">{{ date }}</h5>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="auto">
                <v-btn
                  icon
                  @click="toggleExpand"
                  :class="isExpanded ? 'rotate' : ''"
                >
                  <v-icon size="16">$customChevronDown</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pb-0">
          <v-container fluid>
            <v-row class="px-8" align="end">
              <template v-if="noData">
                <v-col cols="12" class="text-center">
                  <v-img
                    :src="require('@/assets/images/no-data-found.svg')"
                    max-height="100"
                    contain
                  ></v-img>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="3">
                  <GoalLabel
                    :total="salesLabel"
                    :goals="getSalesDetail()"
                  ></GoalLabel>
                </v-col>
                <v-col cols="3">
                  <GoalLabel
                    :total="costsLabel"
                    :goals="getCostsDetail()"
                  ></GoalLabel>
                </v-col>
                <v-col cols="3">
                  <GoalLabel :total="null" :goals="getFoodDetail()"></GoalLabel>
                </v-col>
                <v-col cols="3">
                  <GoalLabel
                    :total="null"
                    :goals="getLaborDetail()"
                  ></GoalLabel>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-text class="px-0">
          <v-expansion-panels v-model="expanded" accordion class="elevation-0">
            <v-expansion-panel :key="0">
              <v-expansion-panel-content eager>
                <Table
                  :headers="headers"
                  :items="monthlyPerformanceTransformed"
                  :footer="false"
                  :total-records="20"
                  :number-of-pages="0"
                >
                  <template v-slot:item="{ index, item, headers }">
                    <tr class="py-2">
                      <td
                        v-for="header in headers"
                        :class="[getTdAlignClass(header.align)]"
                        :key="'td-' + index + '-' + header.value"
                        class="py-2"
                      >
                        <template v-if="header.value === 'month'">
                          <span class="text-month">
                            {{ item.month }}
                          </span>
                        </template>

                        <template v-else-if="header.value === 'attain'">
                          <span
                            :class="getTextColor('normal', item.attain, 100)"
                            >{{ item.attain }}%</span
                          >
                        </template>

                        <template v-else-if="header.value === 'fl.value'">
                          <div>
                            <span
                              :class="
                                getTextColor(
                                  'inverted',
                                  item.fl.value,
                                  item.fl.goal
                                )
                              "
                              >{{ item.fl.value }}%</span
                            >
                            ({{ item.fl.goal }}%)
                          </div>
                          <div class="text-total-fade">
                            {{ item.fl.total | toCurrency }}
                          </div>
                        </template>

                        <template v-else-if="header.value === 'f.value'">
                          <div>
                            <span
                              :class="
                                getTextColor(
                                  'inverted',
                                  item.f.value,
                                  item.f.goal
                                )
                              "
                              >{{ item.f.value }}%</span
                            >
                            ({{ item.f.goal }}%)
                          </div>
                          <div class="text-total-fade">
                            {{ item.f.total | toCurrency }}
                          </div>
                        </template>

                        <template v-else-if="header.value === 'l.value'">
                          <div>
                            <span
                              :class="
                                getTextColor(
                                  'inverted',
                                  item.l.value,
                                  item.l.goal
                                )
                              "
                              >{{ item.l.value }}%</span
                            >
                            ({{ item.l.goal }}%)
                          </div>
                          <div class="text-total-fade">
                            {{ item.l.total | toCurrency }}
                          </div>
                        </template>

                        <template v-else>
                          {{ item[header.value] | toCurrency }}
                        </template>
                      </td>
                    </tr>
                  </template>
                </Table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import GoalLabel from '@/components/admin/partials/GoalLabel/GoalLabel'
import dayjs from 'dayjs'

export default {
  name: 'index',
  components: { GoalLabel, Table },
  props: {
    termName: {
      type: String,
      default: '',
      required: true
    },
    date: {
      type: String,
      default: '',
      required: false
    },
    termPeriod: {
      type: String,
      default: '',
      required: true
    },
    sales: {
      type: Object,
      default: () => {},
      required: true
    },
    costs: {
      type: Object,
      default: () => {},
      required: true
    },
    monthlyPerformance: {
      type: Array,
      default: () => [],
      required: true
    },
    expand: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      expanded: -1,
      headers: [
        {
          text: this.$t('page_company_performance_table_header_month'),
          align: 'center',
          value: 'month',
          sortable: false
        },
        {
          text: this.$t('page_company_performance_table_header_sales'),
          align: 'center',
          value: 'sales'
        },
        {
          text: this.$t('page_company_performance_table_header_goals'),
          align: 'center',
          value: 'goals'
        },
        {
          text: this.$t('page_company_performance_table_header_attain'),
          align: 'center',
          value: 'attain'
        },
        {
          text: this.$t('page_company_performance_table_header_costs'),
          align: 'center',
          value: 'costs'
        },
        {
          text: this.$t('page_company_performance_table_header_fl'),
          align: 'center',
          value: 'fl.value'
        },
        {
          text: this.$t('page_company_performance_table_header_f'),
          align: 'center',
          value: 'f.value'
        },
        {
          text: this.$t('page_company_performance_table_header_l'),
          align: 'center',
          value: 'l.value'
        }
      ],
      search: ''
    }
  },
  mounted() {
    if (this.expand) this.toggleExpand()
  },
  computed: {
    noData() {
      return this.sales.total_amount === 0 && this.costs.total_amount === 0
    },
    isExpanded() {
      return this.expanded === 0
    },
    today() {
      return dayjs().format('YYYY-MM-DD')
    },
    monthlyPerformanceTransformed() {
      let monthlyValues = []
      this.monthlyPerformance.forEach(item => {
        if (!item.sale || !item.cost || !item.goal) return
        monthlyValues.push({
          month: dayjs(item.month.id).format('YYYY/MM'),
          sales: item.sale,
          costs: item.cost,
          goals: item.goal,
          attain: item.attain_percentage,
          fl: {
            value: item.fl_cost.total.percentage,
            goal: item.fl_cost.total.goal_percentage,
            total: item.fl_cost.total.amount
          },
          f: {
            value: item.fl_cost.f_cost.percentage,
            goal: item.fl_cost.f_cost.goal_percentage,
            total: item.fl_cost.f_cost.amount
          },
          l: {
            value: item.fl_cost.l_cost.percentage,
            goal: item.fl_cost.l_cost.goal_percentage,
            total: item.fl_cost.l_cost.amount
          }
        })
      })
      return monthlyValues
    },
    salesLabel() {
      return { label: this.$t('total_sales'), value: this.sales.total_amount }
    },
    costsLabel() {
      return { label: this.$t('total_costs'), value: this.costs.total_amount }
    }
  },
  methods: {
    toggleExpand() {
      if (this.expanded === -1) {
        this.expanded = 0
      } else {
        this.expanded = -1
      }
    },
    getTdAlignClass(align) {
      return align !== null ? 'text-' + align : ''
    },
    getTextColor(mode, value, threshold) {
      let ret = ''
      switch (mode) {
        case 'normal':
          ret = value > threshold ? 'success--text' : 'error--text'
          break
        case 'inverted':
          ret = value > threshold ? 'error--text' : 'success--text'
          break
      }
      return ret
    },

    getSalesDetail() {
      if (!this.sales.supposed || !this.sales.goal) return []
      return [
        {
          name: 'supposedGoal',
          value: this.sales.supposed?.amount,
          label: this.$t('supposed'),
          percentage: `${this.sales.supposed?.percentage}%`,
          color: 'info'
        },
        {
          name: 'goal',
          value: this.sales.goal?.amount,
          label: this.$t('goal'),
          percentage: `${this.sales.goal?.percentage}%`,
          color: 'info'
        }
      ]
    },

    getCostsDetail() {
      if (
        this.costs.fl_cost?.total.amount === 0 ||
        this.sales.fl_sale?.total.amount === 0
      )
        return []
      return [
        {
          name: 'flCost',
          value: this.costs.fl_cost?.total.amount,
          label: this.$t('fl_cost'),
          percentage: `${this.costs.fl_cost?.total.percentage}%`,
          color: 'secondary'
        },
        {
          name: 'flGoal',
          value: this.sales.fl_sale?.total.amount,
          label: this.$t('fl_goal'),
          percentage: `${this.sales.fl_sale?.total.percentage}%`,
          color: 'info'
        }
      ]
    },
    getFoodDetail() {
      if (this.sales.fl_sale === 0 || this.costs.fl_goal === 0) return []
      return [
        {
          name: 'fCost',
          value: this.costs.fl_cost?.f_cost.amount,
          label: this.$t('food'),
          percentage: `${this.costs.fl_cost?.f_cost.percentage}%`,
          color: 'secondary'
        },
        {
          name: 'fGoal',
          value: this.sales.fl_sale?.f_sale.amount,
          label: this.$t('f_goal'),
          percentage: `${this.sales.fl_sale?.f_sale.percentage}%`,
          color: 'info'
        }
      ]
    },
    getLaborDetail() {
      if (this.sales.fl_sale === 0 || this.costs.fl_goal === 0) return []
      return [
        {
          name: 'lCost',
          value: this.costs.fl_cost.l_cost.amount,
          label: this.$t('labor'),
          percentage: `${this.costs.fl_cost.l_cost.percentage}%`,
          color: 'secondary'
        },
        {
          name: 'lGoal',
          value: this.sales.fl_sale?.l_sale.amount,
          label: this.$t('l_goal'),
          percentage: `${this.sales.fl_sale?.l_sale.percentage}%`,
          color: 'info'
        }
      ]
    }
  }
}
</script>

<style lang="scss" src="./Performance.scss" scoped></style>
