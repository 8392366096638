<template>
  <v-row>
    <v-col v-if="getTotal" cols="12" class="py-1">
      <v-row align="center">
        <v-col cols="3" class="text-right">
          <div class="main--text text-subheading-2 font-weight-regular">
            {{ total.label }}
          </div>
        </v-col>
        <v-col cols="auto">
          <div class="main--text text-heading font-weight-medium">
            {{ total.value | toCurrency }}
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-for="item in goals" :key="item.name" cols="12" class="py-1">
      <v-row align="center" v-if="item.value">
        <v-col cols="3" class="text-right">
          <div
            class="text-subheading-2 font-weight-regular text-right"
            :class="getLabelTextColor(item.color)"
          >
            {{ item.label }}
          </div>
        </v-col>
        <v-col cols="auto">
          <div
            class="text-subheading font-weight-regular"
            :class="getLabelTextColor(item.color)"
          >
            {{ item.value | toCurrency }}
          </div>
        </v-col>
        <v-col cols="auto">
          <v-chip
            class="font-weight-regular chip-custom"
            small
            :color="getChipColor(item.color)"
            :text-color="getChipTextColor(item.color)"
            :ripple="false"
            >{{ item.percentage }}
          </v-chip>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'GoalLabel',
  props: {
    total: {
      type: Object,
      default: () => {},
      required: false
    },
    goals: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  computed: {
    getTotal() {
      if (this.total?.value) return this.total
      return false
    }
  },
  methods: {
    getChipColor(color) {
      if (color === 'secondary') {
        return 'rgba(88, 88, 88, 0.15)'
      } else if (color === 'info') {
        return 'rgba(0, 148, 255, 0.15)'
      } else {
        return ''
      }
    },

    getChipTextColor(color) {
      if (color === 'secondary') {
        return '#585858'
      } else if (color === 'info') {
        return '#0094FF'
      } else {
        return ''
      }
    },

    getLabelTextColor(color) {
      if (color === 'secondary') {
        return 'secondary--text'
      } else if (color === 'info') {
        return 'info--text'
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped></style>
