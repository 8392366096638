<template>
  <v-row>
    <v-col
      cols="12"
      v-for="(term, index) in getCompanyPerformances"
      :key="`term-${term.business_year.term}`"
    >
      <Performance
        :expand="index === 0"
        :termName="`${term.business_year.term}期`"
        :termPeriod="termPeriod(term)"
        :sales="term.sales"
        :costs="term.costs"
        :monthly-performance="term.monthly_performance"
        :date="term.date"
      >
      </Performance>
    </v-col>

    <v-col v-if="loading" cols="12">
      <v-card>
        <v-skeleton-loader class="mx-auto" type="card-heading">
        </v-skeleton-loader>

        <v-divider></v-divider>

        <v-card-text>
          <v-skeleton-loader
            max-height="150"
            class="mx-auto"
            type="image"
          ></v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      v-if="this.itemsToBeLoad <= this.getCompanyBusinessYears.length - 1"
      cols="12"
      class="text-center"
    >
      <v-btn outlined text color="primary" @click="loadData">
        {{ $t('load_more') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import Performance from '@/components/admin/partials/Company/Detail/Performance/Performance'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'index',
  components: { Performance },
  created() {
    this.loading = true
    this.$store.commit('COMPANY_PERFORMANCE_RESET')
    this.loadData()
  },
  computed: {
    ...mapGetters(['getCompanyPerformances', 'getCompanyBusinessYears'])
  },
  data() {
    return {
      loading: false,
      terms: [],
      itemsToBeLoad: 3,
      currentIndex: 0
    }
  },
  methods: {
    async loadData() {
      let maxIndex =
        this.getCompanyBusinessYears.length > this.itemsToBeLoad
          ? this.itemsToBeLoad
          : this.getCompanyBusinessYears.length - 1

      this.loading = true

      for (let i = this.currentIndex; i <= maxIndex; i++) {
        await this.$store
          .dispatch('COMPANY_PERFORMANCE_GET', {
            id: this.$route.params.id,
            term: this.getCompanyBusinessYears[i].term
          })
          .finally(() => {
            if (i === maxIndex) this.loading = false
          })

        this.currentIndex++
      }
    },
    termPeriod(term) {
      let startDate = dayjs(term.business_year.start_date)
      let endDate = dayjs(term.business_year.end_date)
      return this.$t('performance_term_label', {
        year_start: startDate.format('YYYY'),
        month_start: startDate.format('MM'),
        year_end: endDate.format('YYYY'),
        month_end: endDate.format('MM')
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
